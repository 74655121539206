<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>

    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center flex-wrap">
            <button
              v-if="!isNativeApp"
              class="btn-outline primary-btn me-3 d-flex my-1"
              @click="$bvModal.show('export-modal')"
            >
              <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
              <p>{{ $t("depreciation.export_file") }}</p>
            </button>
            <div class="position-relative">
              <div class="pinging"></div>
              <div class="ping"></div>
            </div>
          </div>
          <div class="search-box d-flex" style="border-radius: 10px;">
            <img src="@/assets/search-white.svg" width="20px" />
            <input
              type="text"
              v-debounce:700ms="searchByText"
              :placeholder="$t('home.search')"
              v-model="searchText"
            />
          </div>
        </div>
        <MasterTable
          :assetData="getAllAssets"
          :otherColumn="getAllAssetOtherColumn"
          :page="currentPage"
          rowSelection="none"
        />
        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import moment from "moment";
import MasterTable from "../../components/Table/Depreciation/MasterDepreciationTable.vue";
import { mapGetters } from "vuex";
export default {
  name: "ExpiredAsset",
  components: {
    MasterTable,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      selectedAsset: [],
      searchText: "",
      filterOptions: {},
      currentPage: 1,
      totalRows: 1,
      perPage: 50,
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      getAllAssets: "getAllAssetsStore",
      getAllAssetOtherColumn: "getAllAssetOtherColumn",
      assets_count: "assets_count",
      companyName: "companyName",
    }),
    selectedAssetLength() {
      return this.selectedAsset.length > 0;
    },
  },
  watch: {
    currentPage() {
      this.loadAssetData();
    },
  },
  methods: {
    async onExport(isMail) {
      this.isLoading = true;
      try {
        const res = await this.$store.dispatch("ExportAsset", {
          text: this.searchText && this.searchText.length > 0 ? this.searchText : null,
          depreciation_status: "expire",
          company_id: this.company_id,
          filename: `${this.$t("depreciation.asset_expired_use")}_${
            this.companyName
          }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`,
          type: "expire",
          selected_assets: [],
        });
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async searchByText() {
      this.filterOptions.text = this.searchText;
      this.currentPage = 1;
      this.loadAssetData();
    },
    async loadAssetData() {
      this.selectedAsset = [];
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      await this.$store.dispatch("GetExpireList", {
        text: this.searchText && this.searchText.length > 0 ? this.searchText : null,
        page: this.currentPage,
        company_id: this.company_id,
        limit: this.perPage,
      });
      this.totalRows = this.assets_count;
      this.isLoading = false;
    },
  },
  mounted() {
    this.loadAssetData();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
}

.form-control {
  min-width: 200px;
  width: 200px;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

.input-arrow {
  user-select: none;
  cursor: pointer;
  min-width: 200px;
  width: 200px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 0.375rem 0.75rem;
  color: #212529;
  border-radius: 0.25rem;
  background-image: url("../../assets/down-arrow-black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  padding-right: 40px !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.confirm-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  border-radius: 10px;
  border: none;

  &-red {
    background: #ed1616;
  }
}

.cancel-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: #888;
  border-radius: 10px;
  border: none;
}

/*
  Animation
*/
</style>
